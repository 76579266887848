:root {
    --image-width: 200px;
    /* Default image width */
}

.main-i {
    background-color: #f1f1f1;
    padding: 30px 20px;
    height: 200px;
    text-align: center;
}

h2 {
    color: rgba(3, 110, 130, 1);
    font-size: 2rem;
    margin-bottom: 20px;
}

.store-b {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.store-i {
    width: var(--image-width);
    height: auto;
}

@media (min-width: 1440px) {
    .main-i {
        height: auto;
    }
}

@media (max-width: 768px) {
    h2 {
        font-size: 30px;
    }

    .store-i {
        width: 140px;
    }
}

@media (max-width: 480px) {
    h2 {
        font-size: 28px;
    }

    .store-i {
        width: 100px;
    }
}