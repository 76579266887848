.footer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 16px 40px;
    background-color: rgba(3, 110, 130, 1);
    color: #060505;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    min-height: 200px;
}

.footer-logo {
    width: 140px;
    height: 60px;
    margin-top: 20px;
}

.footer-container {
    display: flex;
    justify-content: space-between;
    height: auto;

}

.footer-section {
    flex-basis: 20%;
    /* Adjusted to fit more sections */
    padding: 0 1rem;
}

.footer-section h4 {
    font-size: 18px;
    margin-bottom: 1rem;
    color: #111010;
}

.footer-section p,
.footer-section a {
    font-size: 0.9rem;
    color: #cdd4f6;
    text-decoration: none;
    line-height: 1.6;
}

.footer-section a:hover {
    color: #3b2d2d;
    text-decoration: underline;
}

.footer-social {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
}

.footer-social a {
    display: inline-block;
    width: 40px;
    height: 40px;
    background-color: #555;
    color: #fff;
    border-radius: 50%;
    text-align: center;
    line-height: 40px;
    margin-right: 0.5rem;
    margin-left: 0.5rem;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.telecharger {
    font-weight: 600;
    margin-top: 1rem;
    color: #111010;
}

.footer-social a:hover {
    background-color: #777;
    transform: scale(1.1);
}

.store img {
    width: 110px;
    cursor: pointer;
}

.footer-bottom p {
    text-align: center;
    padding: 1rem 0;
    width: 100%;
    font-size: 15px;
    color: #030303;
}



.footer-section ul {
    list-style-type: disc;
    padding-left: 20px;
}

.footer-section ul li {
    margin-bottom: 0.5rem;
}

/* Media Queries for responsiveness */
@media (max-width: 907px) {
    .footer-container {
        flex-direction: column;
        align-items: center;
        padding: 1rem;
    }

    .footer-section {
        flex-basis: 100%;
        text-align: center;
        padding: 0.5rem 0;
    }

    .footer-section h4 {
        font-size: 1rem;
    }

    .footer-section p,
    .footer-section a {
        font-size: 0.8rem;
    }

    .footer-logo {
        width: 120px;
        height: 48px;
    }

    .store img {
        width: 110px;
    }
}

@media (max-width: 480px) {
    .footer {
        padding: 0.5rem 1rem;
    }

    .footer-section h4 {
        font-size: 0.9rem;
    }

    .footer-section p,
    .footer-section a {
        font-size: 0.75rem;
    }

    .footer-logo {
        width: 100px;
        height: 40px;
    }

    .store img {
        width: 100px;
    }

        .footer-bottom p {
            text-align: center;
            padding: 1rem 0;
            width: 100%;
            font-size: 13px;
            color: #030303;
        }
}
