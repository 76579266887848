/* Common styles remain the same */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&family=Athiti:wght@400;700&family=Roboto:wght@500&display=swap');

.service {
    background-color: #ffffff;
    padding-bottom: 40px;
}

.service-container {
    text-align: center;
    padding: 40px 20px;
    font-family: 'Poppins', sans-serif;
    background-color: #fff;
    margin: 0px auto;
    max-width: 1200px;
}

.service-container h1 {
    color: #036e82;
    font-size: 36px;
    margin-bottom: 40px;
}

.swiper {
    padding: 20px;
}

.service-static-layout {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
}

.service-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
    position: relative;
    overflow: hidden;
}

.service-item:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.service-image-container {
    width: 100%;
    height: 170px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px 10px 0 0;
    overflow: hidden;
    position: relative;
    background: linear-gradient(135deg, rgb(6, 108, 126) 0%, rgba(3, 110, 130, 1) 100%);
}

.service-image {
    width: 45%;
    height: auto;
    max-height: 200px;
    border-radius: 50%;
    z-index: 1;
}

.service-text {
    text-align: center;
    padding: 20px;
    flex-grow: 1;
    z-index: 1;
}

.service-text h2 {
    font-size: 22px;
    color: #036e82;
    margin: 20px 0 10px;
    font-family: 'Roboto', sans-serif;
}

.service-text p {
    font-size: 16px;
    color: #333333;
    margin: 10px 0 20px;
    font-family: 'Athiti', sans-serif;
    font-weight: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
}

/* Different shapes for different service items */
.item1 .shape-background::before {
    content: "";
    position: absolute;
    top: -50px;
    right: -50px;
    width: 200px;
    height: 200px;
    background-color: #B0CFCC;
    clip-path: ellipse(100% 90% at 60% 5%);
    z-index: 0;
}

.item2 .shape-background::before {
    content: "";
    position: absolute;
    top: -50px;
    right: -50px;
    width: 200px;
    height: 200px;
    background-color: #B0CFCC;
    clip-path:ellipse(100% 90% at 60% 5%);
    z-index: 0;
}

.item3 .shape-background::before {
    content: "";
    position: absolute;
    top: -50px;
    right: -50px;
    width: 200px;
    height: 200px;
    background-color: #B0CFCC;
    clip-path: ellipse(100% 90% at 60% 5%);
    z-index: 0;
}

.item4 .shape-background::before {
    content: "";
    position: absolute;
    top: -50px;
    right: -50px;
    width: 200px;
    height: 200px;
    background-color: #B0CFCC;
    clip-path: ellipse(100% 90% at 60% 5%);
    z-index: 0;
}

.item5 .shape-background::before {
    content: "";
    position: absolute;
    top: -50px;
    right: -50px;
    width: 200px;
    height: 200px;
    background-color: #B0CFCC;
    clip-path: ellipse(100% 90% at 60% 5%);
    z-index: 0;
}

@media (min-width: 769px) {
    .service-container h1 {
        font-size: 32px;
    }

    .service-item {
        width: calc(50% - 30px);
    }
}

@media (min-width: 1024px) {
    .service-container h1 {
        font-size: 36px;
    }

    .service-item {
        width: calc(33.33% - 30px);
    }
}

@media (min-width: 1440px) {
    .service-container h1 {
        font-size: 40px;
    }

    .service-item {
        width: calc(33.33% - 30px);
    }
}