.privacy-page {
    width: 80%;
        margin: 90px auto;
        background-color: #ffffff;
        padding: 30px;
        border-radius: 10px;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

.privacy-page h1 {
    color: #333;
    font-size: 2em;
    margin-bottom: 10px;
    border-bottom: 2px solid #e0e0e0;
    padding-bottom: 10px;
}

.privacy-page .update-date {
    font-style: italic;
    color: #888;
    margin-bottom: 20px;
}

.privacy-page h2 {
    color: #555;
    margin-top: 30px;
    font-size: 1.5em;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 8px;
}

.privacy-page p {
    color: #666;
    margin-bottom: 15px;
}

.privacy-page ul {
    list-style-type: disc;
    padding-left: 20px;
    color: #555;
    margin-bottom: 20px;
}

.privacy-page a {
    color: #007BFF;
    text-decoration: none;
}

.privacy-page a:hover {
    text-decoration: underline;
}