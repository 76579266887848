.politique-page-sitter {
    width: 80%;
        margin: 90px auto;
        background-color: #ffffff;
        padding: 30px;
        border-radius: 10px;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

.politique-page-sitter h1 {
    color: #333;
    font-size: 2em;
    margin-bottom: 20px;
    border-bottom: 2px solid #e0e0e0;
    padding-bottom: 10px;
}

.politique-page-sitter h2 {
    color: #555;
    margin-top: 30px;
    font-size: 1.5em;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 8px;
}

.politique-page-sitter p {
    color: #666;
    line-height: 1.6;
    margin-bottom: 15px;
}

.politique-page-sitter ul,
.politique-page-sitter ol {
    margin-left: 20px;
    color: #555;
    margin-bottom: 20px;
}

.politique-page-sitter li {
    margin-bottom: 10px;
}

.politique-page-sitter a {
    color: #007BFF;
    text-decoration: none;
}

.politique-page-sitter a:hover {
    text-decoration: underline;
}