@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&family=Athiti:wght@400;700&display=swap');

@font-face {
    font-family: 'Jersey 716';
    src: url('../../../Assets/Font/Jersey 716.ttf') format('truetype');
}

.container-offer {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Arial', sans-serif;
    background-color: #fff;
    z-index: 0;
    margin-top: 30px ;

    padding: 10px 10px 20px 10px;

    /* Add padding to shift content to the right */
}
.heading {
    font-size: 40px;
    color: #333;
    text-align: center;
    margin: 25px 0px;
}
.content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}


.left-section {
    display: flex;
    flex-direction: column;
    align-items: center;


}

.second-partie {
    font-size: 16px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    background-color: rgba(176, 207, 203, 0.754);
    border-radius: 20px;
    padding: 1em;
    max-width: 450px;
    width: 90%;
    margin: 1em 0;
}

.center {
    display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        font-family: 'Arial', sans-serif;

    
    
        position: relative;
}

.banner {
    padding: 0.5em;
    text-align: center;
    width: 100%;
    background-color: rgba(244, 123, 104, 1);
    border-radius: 20px;
    margin: 1em 0;
    position: relative;
}

.banner i {
    font-family: 'Athiti', sans-serif;
    font-weight: bold;
}

.banner::before {
    content: '';
    position: absolute;
    width: 15px;
    height: 30px;
    left: -1px;
    top: 50px;
    background-color: #d9534f;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    z-index: 2;
}

.banner::after {
    content: '';
    position: absolute;
    width: 15px;
    height: 15px;
    left: -1px;
    top: 50px;
    background-color: rgba(244, 123, 104, 1);
}

.discount {
    display: inline-block;
    font-size: 40px;
    position: relative;
    color: rgba(244, 123, 104, 1);
    justify-content: space-between;
    font-family: serif;
}

.offer {
    display: inline-block;
    align-items: center;
    color: rgba(3, 110, 130, 1);
font-family: serif;
}

.offer h3 {
    color: rgba(3, 110, 130, 1);
    font-family: serif;
}



.download {
    font-size: 1.2em;
    color: rgba(244, 123, 104, 1);
    margin: 19px 0px;
    display: flex;
    justify-content: center;
}



.error-message,
.success-message {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 20px;
    width: 100%;
    max-width: 300px;
    text-align: center;
    position: absolute;
    /* Enable absolute positioning */
    bottom: 5px;
    /* Adjust as needed */
    left: 50%;
    transform: translateX(-50%);
    animation: slide-in 0.5s ease-out, slide-out 0.5s ease-in 4.5s forwards;
}

@keyframes slide-in {
    from {
        opacity: 0;
        transform: translateX(-50%) translateY(20px);
    }

    to {
        opacity: 1;
        transform: translateX(-50%) translateY(0);
    }
}

@keyframes slide-out {
    from {
        opacity: 1;
        transform: translateX(-50%) translateY(0);
    }

    to {
        opacity: 0;
        transform: translateX(-50%) translateY(20px);
    }
}

.error-message {
    background-color: #f8d7da;
    color: #721c24;
}

.success-message {
    background-color: #d4edda;
    color: #155724;
}

.sib-icon {
    margin-right: 10px;
    width: 24px;
    height: 24px;
    fill: currentColor;
}

.sib-form-message-panel {
    text-align: center;
    margin: 20px;
}

.sib-form-message-panel__inner-text {
    font-size: 14px;
    display: inline-block;
    vertical-align: middle;
}

.input--hidden {
    display: none;
}

.registration-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 50px;
}

.registration-form h2 {
    color: rgba(3, 110, 130, 1);
    font-size: 2rem;
    margin-bottom: 40px;
}

.form-group {
    width: 100%;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 19px;
}



.registration-form input {
    width: 100%;
    max-width: 550px;
    padding: 10px;
    border: 0.5px solid #ccc;
    border-radius: 9px;
    font-size: 16px;
}

.registration-form button {
    width: 100%;
    max-width: 200px;
    padding: 10px;
    background-color: #000;
    border-radius: 9px;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin: 20px 0px;
    box-shadow: 0 8px 8px rgba(0, 0, 0, 0.3);
}

.registration-form button:hover {
    background-color: #333;
    box-shadow: 0 12px 12px rgba(0, 0, 0, 0.3);
}


.image-container {
    align-self: center;
}

.image-container img {
    width: 130%;
    max-width: 400px;
    border-radius: 50%;
    padding-left: 20px;
    padding-right: 20px;
}

/* Responsive Styles */

@media only screen and (max-width: 8560px) {
    .content { 
        display: flex;
        
        padding: 0px 0px 0px 0px;
    }

    .heading {
        font-size: 47px;
    }

    .left-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 700px;
        width: 100%;
    }

    .second-partie {
        padding: 0.5em;
        width: 140%;
        max-width: 600px;
        height: 350px;

    }

    .banner {
        position: relative;
        width: 570px;
        height: 50px;
        left: -23px;
        border-bottom-left-radius: 0;
    }

    .banner i {
        font-family: 'Athiti', sans-serif;
        font-size: 26px;
    }

    .banner::before {
        content: '';
        position: absolute;
        width: 15px;
        height: 25px;
        left: 0;
        top: 49px;
        background-color: #d9534f;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        z-index: 2;
    }

    .banner::after {
        content: '';
        position: absolute;
        width: 12px;
        height: 14px;
        left: 0;
        top: 49.5px;
        background-color: rgba(244, 123, 104, 1);
    }



    .discount {
        font-family: serif;
        font-size: 83px;
    }

    .offer {
        font-family: serif;
        font-size: 42px;
    }

    .download {
        font-size: 33px;
    }
    .image-container img {
        position: relative;
        width: 170%;
        max-width: 420px;
        left: 50px;
        border-radius: 50%;


    }
}

@media only screen and (max-width: 1440px) {
    .content {
        display: flex;
        padding: 0px 0px 0px 0px;
    }

    .heading {
        font-size: 47px;
    }

    .left-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 600px;
        width: 100%;
    }

    .second-partie {
        padding: 0.5em;
        width: 140%;
        max-width: 570px;
        height: 330px;

    }

    .banner {
        position: relative;
        width: 530px;
        height: 50px;
        left: -23px;
        border-bottom-left-radius: 0;
    }

        .banner i {
          padding-left: 70px;
            font-family: 'Athiti', sans-serif;
            font-size: 25px;
        }

    .banner::before {
        content: '';
        position: absolute;
        width: 15px;
        height: 25px;
        left: 0;
        top: 49px;
        background-color: #d9534f;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        z-index: 2;
    }

    .banner::after {
        content: '';
        position: absolute;
        width: 12px;
        height: 14px;
        left: 0;
        top: 49.5px;
        background-color: rgba(244, 123, 104, 1);
    }

    .discount {
        font-size: 79px;
    }

    .offer {
        font-size: 35px;
    }

    .download {
        font-size: 33px;
    }
    .image-container img {
        position: relative;
        width: 170%;
        max-width: 450px;
        left: 15px;
        border-radius: 50%;


    }
}

@media only screen and (max-width: 1300px) {
    .content {
        display: flex;
        padding: 0px 0px 0px 40px;
    }

    .heading {
        font-size: 35px;
    }
.left-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 500px;
    width: 100%;
}
    .second-partie {
        padding: 0.5em;
        width: 100%;
        height: 320px;
    }

    .banner {
        position: relative;
        width: 450px;
        height: 48px;
        left: -21px;
        border-bottom-left-radius: 0;
    }
        .banner i {
            font-family: 'Athiti', sans-serif;
            font-size: 22px;
        }

    .banner::before {
        content: '';
        position: absolute;
        width: 13px;
        height: 25px;
        left: 0;
        top: 48px;
        background-color: #d9534f;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        z-index: 2;
    }

    .banner::after {
        content: '';
        position: absolute;
        width: 12px;
        height: 14px;
        left: 0;
        top: 46px;
        background-color: rgba(244, 123, 104, 1);
    }

    .countdown {
        font-size: 20px;
    }

    .discount {
        font-size: 66px;
    }

    .offer {
        font-size: 29px;
    }

    .download {
        font-size: 27px;
    }

.image-container img {
    position: relative;
    width: 170%;
    max-width: 420px;
    left: 10px;
    border-radius: 50%;


}
}

@media only screen and (max-width: 1024px) {
    .content{

        padding: 0px 0px 0px 40px;

    }
    .heading {
        font-size: 35px;
    }

    .left-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 55%;
    
    
}

    .second-partie {
        padding: 0.5em;
        width: 100%;
        height: 320px;
    }

    .banner {
        position: relative;
        width: 400px;
        height: 44px;
        left: -21px;
        border-bottom-left-radius: 0;
    }

        .banner i {
            font-family: 'Athiti', sans-serif;
            font-size: 19px;
        }

    .banner::before {
        content: '';
        position: absolute;
        width: 13px;
        height: 25px;
        left: 0;
        top: 43.5px;
        background-color: #d9534f;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        z-index: 2;
    }

    .banner::after {
        content: '';
        position: absolute;
        width: 12px;
        height: 14px;
        left: 0;
        top: 41.5px;
        background-color: rgba(244, 123, 104, 1);
    }

    .countdown {
        font-size: 20px;
    }

    .discount {
        font-size: 45px;
    }

    .offer {
        font-size: 27px;
    }

    .download {
        font-size: 28px;
    }

        .image-container img {
            position: relative;
            left: 10px;
            width: 100%;
            max-width: 420px;
            border-radius: 50%;

        }
}
@media only screen and (max-width: 881px) {
    .content {
        display: contents;
        padding: 0%;
    }

    .heading {
        font-size: 32px;
    }

    .second-partie {
        padding: 0.5em;
        width: 95%;
        height: 320px;
    }

    .left-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 98%;

    }

    .banner {
        position: relative;
        width: 390px;
        height: 47px;
        left: -21px;
        border-bottom-left-radius: 0;
    }

    .banner i {
        font-family: 'Athiti', sans-serif;
        padding-left: 30px;
        font-size: 20px;
    }

    .banner::before {
        content: '';
        position: absolute;
        width: 13px;
        height: 25px;
        left: 0;
        top: 47px;
        background-color: #d9534f;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        z-index: 2;
    }

    .banner::after {
        content: '';
        position: absolute;
        width: 12px;
        height: 14px;
        left: 0;
        top: 44px;
        background-color: rgba(244, 123, 104, 1);
    }

    .discount {
        font-size: 62px;
    }

    .offer {
        font-size: 30px;
    }

    .download {
        font-size: 27px;
    }


    .image-container img {
        display: none;
    }
}
@media only screen and (max-width: 702px) {
    .content {
            display: contents;
            padding: 0%;
        }
    .heading {
        font-size: 32px;
    }

    .second-partie {
            padding: 0.5em;
            width: 95%;
            height: 320px;
        }

    .left-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 98%;
    
}
    
        .banner {
            position: relative;
            width: 390px;
            height: 47px;
            left: -21px;
            border-bottom-left-radius: 0;
        }

        .banner i {
            font-family: 'Athiti', sans-serif;
            padding-left: 30px;
            font-size: 20px;
        }
    
        .banner::before {
            content: '';
            position: absolute;
            width: 13px;
            height: 25px;
            left: 0;
            top: 47px;
            background-color: #d9534f;
            border-top-left-radius: 20px;
            border-bottom-left-radius: 20px;
            z-index: 2;
        }
    
        .banner::after {
            content: '';
            position: absolute;
            width: 12px;
            height: 14px;
            left: 0;
            top: 44px;
            background-color: rgba(244, 123, 104, 1);
        }

    .discount {
        font-size: 57px;
    }

    .offer {
        font-size: 25.5px;
    }

    .download {
        font-size: 27px;
    }


    .image-container img {
        display: none;
    }
}

@media only screen and (max-width: 467px) {
    .container-offer {
            padding: 0px 10px;
        }

    .heading {
        font-size: 29px;
    }

        .left-section {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 90%;
    
        }

    .second-partie {
        padding: 0.5em;
        width: 100%;
        height: 320px;
    }

    .banner {
        
        position: relative;
        width: 340px;
        height: 42px;
        left: -21px;
        border-bottom-left-radius: 0;
    }
    .banner i {
    font-family: 'Athiti', sans-serif;
    padding-left: 30px;
    font-size: 17px;
}

    
    .banner::before {
        content: '';
        position: absolute;
        width: 13px;
        height: 25px;
        left: -0px;
        top: 41.5px;
        background-color: #d9534f;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        z-index: 2;
    }
    
    .banner::after {
        content: '';
        position: absolute;
        width: 12px;
        height: 14px;
        left: -0px;
        top: 41.5px;
        background-color: rgba(244, 123, 104, 1);
    
    }

    .discount {
        font-size: 40px;
    }

    .offer {
        font-size: 22px;
    }

    .download {
        font-size: 27px;
    }

        .image-container {
            display: none;
        }
    

}

@media only screen and (max-width: 412px) {
    .container-offer {
            padding: 0px 5px;
        }

.left-section {
    display: flex;
    flex-direction: column;
    align-items: center;
        width: 90%;


}
.second-partie {
        padding: 0.5em;
        width: 100%;
        height: 330px;
    }
    .banner {
    
        position: relative;
        width: 320px;
        height: 42px;
        left: -21px;
        border-bottom-left-radius: 0;
    }

        .banner i {
            font-family: 'Athiti', sans-serif;
            padding-left: 30px;
            font-size: 16px;
        }
        .banner i {
    font-family: 'Athiti', sans-serif;
    font-weight: bold;
}
    
    .banner::before {
        content: '';
        position: absolute;
        width: 13px;
        height: 25px;
        left: -0px;
        top: 41.5px;
        background-color: #d9534f;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        z-index: 2;
    }
    
    .banner::after {
        content: '';
        position: absolute;
        width: 12px;
        height: 14px;
        left: -0px;
        top: 41.5px;
        background-color: rgba(244, 123, 104, 1);
    
    }

    .heading {
        font-size: 24px;
    }
    .second-partie {
        width: 100%;
    }

    .discount {
        font-size: 41px;
    }



    .offer {
        font-size: 19px;
    }



    .download {
        font-size: 28px;
    }


        .image-container {
            display: none;
        }
}

@media only screen and (max-width: 366px) {
    .container-offer {
            padding: 0px 2px;
        }

    
.left-section {
    display: flex;
    flex-direction: column;
    align-items: center;
        width: 100%;
            max-width: 300px;

}

.second-partie {
        padding: 0.5em;
        width: 100%;
        height: 320px;
    }
    .banner {
        
        position: relative;
        width: 260px;
        height: 41.5px;
        left: -21px;
        border-bottom-left-radius: 0;
    }
    .banner i {
    font-family: 'Athiti', sans-serif;
    padding-left: 35px;
    font-size: 14.5px;
}

    .banner::before {
        content: '';
            position: absolute;
            width: 13px;
            height: 25px;
            left: -0px;
            top: 41.5px;
            background-color: #d9534f;
            border-top-left-radius: 20px;
            border-bottom-left-radius: 20px;
            z-index: 2;
    }
    .banner::after {
            content: '';
            position: absolute;
            width: 12px;
                height: 14px;
                left: -0px;
                top: 41.5px;
            background-color: rgba(244, 123, 104, 1);
    
    }
    .heading {
       font-size: 20px;
    }

    .second-partie {
        width: 95%;
    }
    .discount {
        font-size: 42px;
    }

    .offer {
        font-size: 17px;
    }

    .download {
      font-size: 22px;
    }
    .image-container{
        display: none;
    }

}


