@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600;700&family=Athiti:wght@400;700&display=swap');

@font-face {
    font-family: 'Kitsch-Text-Extralight-trial';
    src: url('../../../Assets/Font/KitschText-Extralight.ttf') format('truetype');
}

.apropos {
    background-image: url('./Image/backdog.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center top;
}

.propos-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px;
    position: relative;
    z-index: 1;
    border-radius: 10px;
}

.propos-container h1 {
    font-weight: bold;
    font-size: 40px;
    margin-bottom: 40px;
    color: #ffffff;
    text-shadow: 0 10px 10px #000000;
    text-align: center;
}

.propos-item {
    display: flex;
    align-items: center;
    background-color: #ffffff;
    margin-bottom: 40px;
    border-radius: 10px;
    overflow: hidden;
    transition: all 0.3s ease;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.propos-item:hover {
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
}

.propos-item img {
    width: 30%;
    height: auto;
    object-fit: cover;
    border-radius:10px;
}

.propos-item.even {
    flex-direction: row-reverse;
}

.propos-item.even img {
    border-radius: 0 10px 10px 0;
}

.propos-content {
    padding: 20px;
    width: 80%;
}

.propos-content h2 {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 32px;
    margin-bottom: 20px;
    color: rgba(176, 207, 203, 1);
}

.propos-content p {
    font-family: 'Athiti', sans-serif;
    font-size: 20px;
    margin-bottom: 20px;
    color: #333;
}

/* Media Queries for responsiveness */
@media (max-width: 1024px) {
    .propos-container {
        padding: 20px;
    }

    .propos-item img {
        width: 30%;
    }

    .propos-content {
        width: 70%;
    }

    .propos-content h2 {
        font-size: 28px;
    }

    .propos-content p {
        font-size: 18px;
    }
}

@media (max-width: 768px) {
    .propos-item {
        flex-direction: column;
        text-align: center;
        margin-bottom: 20px;
        padding: 10px;

    }

    .propos-item img {
        width: 90%;
        max-width: 290px;
        
    }

    .propos-content {
        width: 100%;
        padding: 10px;
    }

    .propos-content h2 {
        font-size: 24px;
    }

    .propos-content p {
        font-size: 16px;
    }

    .propos-item.even {
        flex-direction: column;
    }

    .propos-item.even img {
        border-radius: 10px;
    }
}