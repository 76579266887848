@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&family=Athiti:wght@400;700&display=swap');



.Acceuil {
    position: relative;
    top: 30px;
    width: 100%;
    background: url(./backdog.png) no-repeat center center;
    background-size: cover;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;


}

.Acceuil-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    padding: 16px;
}

.heading-container {
    font-size: 16px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.654), rgba(168, 165, 165, 0.654));
    border-radius: 20px;
    padding: 32px;
    width: 100%;
    max-width: 600px;
    margin: 16px;
    text-align: center;
    position: relative;
}

.main-heading {
    margin-bottom: 10px;
    animation: FadeIn 3s;
    font-family: 'Poppins', sans-serif;
    font-size: 40px;
}

.description {
    font-size: 19px;
    margin-bottom: 10px;
    font-family: 'Athiti', sans-serif;
}

.buttons {
    display: flex;
    justify-content: center;
    gap: 16px;
    width: 100%;
}

.str1,
.str2 {
    max-width: 250px;
    max-height: 100px;
    cursor: pointer;
    font-size: 24px;
}

@keyframes FadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

/* Responsive Styles */
@media (max-width: 1500px) {
    .Acceuil {
        min-height: 96vh;
    }

    .Acceuil-main {
        flex-direction: row;
        justify-content: flex-start;
    }

    .heading-container {
        padding: 24px;
        text-align: left;
        max-width: 680px;
        margin-top: 2%;
    }

    .main-heading {
        font-size: 36px;
    }

    .description {
        font-size: 26px;
    }

    .str1,
    .str2 {
        max-width: 220px;
        max-height: 90px;
    }
}

@media (max-width: 1300px) {
    .Acceuil {
        min-height: 100vh;
    }

    .Acceuil-main {
        flex-direction: row;
        justify-content: flex-start;
    }

    .heading-container {
        padding: 24px;
        text-align: left;
        max-width: 570px;
        margin-top: 2%;
    }

    .main-heading {
        font-size: 32px;
    }

    .description {
        font-size: 18px;
    }

    .str1,
    .str2 {
        max-width: 200px;
        max-height: 80px;
    }
}

@media (max-width: 1024px) {
    .Acceuil {
        min-height: 80vh;
            margin-top: 25px;
    }

    .Acceuil-main {
        flex-direction: row;
        justify-content: flex-start;
    }

    .heading-container {
        padding: 16px;
        text-align: left;
        max-width: 500px;
        margin-top: 5%;
    }

    .main-heading {
        font-size: 28px;
    }

    .description {
        font-size: 19px;
    }

    .str1,
    .str2 {
        max-width: 170px;
        max-height: 70px;
    }
}

@media (max-width: 900px) {
    .Acceuil {
        min-height: 85vh;
            margin-top: 23px;
    }

    .Acceuil-main {
        flex-direction: column;
        justify-content: center;
    }

    .heading-container {
        padding: 16px;
        text-align: center;
        max-width: 400px;
        margin: 0 auto;
        margin-top: 0%;
    }

    .main-heading {
        font-size: 23px;
    }

    .description {
        font-size: 17px;
    }

    .str1,
    .str2 {
        max-width: 170px;
        max-height: 70px;
    }
}

@media (max-width: 500px) {
    .Acceuil {
        min-height: 55vh;
            margin-top: 22px;
    }

    .Acceuil-main {
        flex-direction: column;
        justify-content: center;
    }

    .heading-container {
        padding: 8px;
        text-align: center;
        max-width: 350px;
        margin: 0 auto;
        margin-top: 25%;
    }

    .main-heading {
        font-size: 20px;
    }

    .description {
        font-size: 16px;
    }

    .str1,
    .str2 {
        max-width: 150px;
        max-height: 60px;
    }
}

@media (max-width: 375px) {
    .Acceuil {
        min-height: 50vh;
            margin-top: 22px;
    }

    .heading-container {
        padding: 8px;
        text-align: center;
        max-width: 300px;
        margin: 0 auto;
        margin-top: 30%;
    }

    .main-heading {
        font-size: 15px;
    }

    .description {
        font-size: 13px;
    }

    .str1,
    .str2 {
        max-width: 100px;
        max-height: 50px;
    }
}