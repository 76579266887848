/*.license {
    background-image: url('../../Apropos/Image/backdog.png');
    background-size: cover;
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-position: center top;
}*/

.license-page {

    width: 80%;
    margin: 90px auto;
    background-color: #ffffff;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

.license-page header {
    margin-bottom: 20px;
    text-align: center;
}

.license-page h1 {
    color: #333;
        font-size: 2em;
        margin-bottom: 20px;
        border-bottom: 2px solid #e0e0e0;
        padding-bottom: 10px;
}

.license-page h2 {
    color: #555;
        margin-top: 30px;
        font-size: 1.5em;
        border-bottom: 1px solid #e0e0e0;
        padding-bottom: 8px;
}

.license-page p {
    color: #666;
        line-height: 1.6;
        margin-bottom: 15px;
}

.license-page ol {
    margin-left: 20px;
    padding-left: 20px;
    color: #666;
}

.license-page li {
    margin-bottom: 5px;
    line-height: 1.6;
}

.license-page a {
    color: #0077cc;
    text-decoration: none;
}

.license-page a:hover {
    text-decoration: underline;
}