@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: "Outfit", sans-serif;
}

.header-container {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: #ffffff;
    z-index: 1000;
    padding: 10px 20px;
    border-bottom: 1px solid rgb(160, 158, 158);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo {
    max-width: 90%;
    width: 140px;
}

.nav-links {
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 18px;
    background-color: #ffffff;
}

.nav-links a {
    list-style: none;
    margin: 0 15px;
    padding: 10px;
    color: rgba(3, 110, 130, 1);
    cursor: pointer;
    text-decoration: none;
}

.header-container .active {
    padding-bottom: 2px;
    border-bottom: 2px solid rgba(176, 207, 203, 1);
}

.sitters {
    border: 2px solid rgba(3, 110, 130, 1);
    color: rgba(3, 110, 130, 1);
    padding: 10px 10px;
    border-radius: 5px;
    text-decoration: none;
}

.sitters:hover {
    background-color: rgba(176, 207, 203, 1);
    color: rgba(3, 110, 130, 1);
}

.hamburger {
    display: none;
    flex-direction: column;
    cursor: pointer;
    gap: 4px;
}

.hamburger .bar {
    width: 25px;
    height: 3px;
    background-color: rgba(3, 110, 130, 1);
    border-radius: 5px;
}

.right-side {
    display: flex;
    align-items: center;
    gap: 20px;
}

/* Responsive Design */



@media (max-width: 1020px) {
    .nav-links {
        position: absolute;
        top: 0;
        right: 0;
        height: 350px;
        width: 700px;
        border-radius: 0px 0px 0px 12px;
        background-color: rgba(176, 207, 203, 1);
        border-top: 1px solid rgb(160, 158, 158);
        flex-direction: column;
        align-content: center;
        width: 70%;
        max-width: 300px;
        transform: translateX(100%);
        transition: transform 0.3s ease-in-out;
        margin-top: 64px;
        padding-left: 12px;
        gap: 30px;
    }

    .nav-links.open {
        
        transform: translateX(0);
    }

    .nav-links a {
        font-size: 14px;
        width: 80%;
    }

    .hamburger {
        display: flex;
    }

    .header-container .active {
        position: relative;
        padding-bottom: 2px;
        border-bottom: 2px solid rgba(3, 110, 130, 1);
    }

    .header-container {
        position: fixed;
        top: 0;
        width: 100%;
        background-color: #ffffff;
        z-index: 1000;
        padding: 10px 20px;
        border-bottom: 1px solid rgb(160, 158, 158);
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .logo {
        max-width: 90%;
        width: 90px;
    }
}

@media (max-width: 340px) {
    .nav-links {
        position: absolute;
        top: 0;
        right: 0;
        height: 350px;
        width: 700px;
        border-radius: 0px 0px 0px 12px;
        background-color: rgba(176, 207, 203, 1);
        border-top: 1px solid rgb(160, 158, 158);
        flex-direction: column;
        align-content: center;
        width: 70%;
        max-width: 300px;
        transform: translateX(100%);
        transition: transform 0.3s ease-in-out;
        margin-top: 84px;
        padding-left: 12px;
        gap: 30px;
    }

    .nav-links.open {

        transform: translateX(0);
    }

    .nav-links a {
        font-size: 14px;
        width: 80%;
    }

    .hamburger {
        display: flex;
    }

    .header-container .active {
        position: relative;
        padding-bottom: 2px;
        border-bottom: 2px solid rgba(3, 110, 130, 1);
    }

    .header-container {
        position: fixed;
        top: 0;
        width: 100%;
        background-color: #ffffff;
        z-index: 1000;
        padding: 10px 20px;
        border-bottom: 1px solid rgb(160, 158, 158);
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .logo {
        max-width: 90%;
        width: 90px;
    }
}